<template>
  <v-row>
    <v-col cols="10">
      <h4 class="text-h5">
        Nowy kontrahent
      </h4>
    </v-col>
    <v-col cols="2">
      <v-btn color="primary">Zapisz</v-btn>
    </v-col>
  </v-row>
</template>

<script>
// import DeviceService from "@/services/DeviceService";

export default {
  data() {
    return {
      // user: {}
    }
  },
  components: {},
  // beforeMount() {
  //   if (this.$route.params.id != 'new') {
  //     DeviceService.getUser(this.$route.params.id).then(async response => {
  //       this.user = response.data.data
  //       this.user.password = undefined
  //     })
  //   } else {
  //     this.user = {
  //       name: '',
  //       surname: '',
  //       email: '',
  //       active: true
  //     }
  //   }
  // }
}
</script>
